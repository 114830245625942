import { Button, ButtonGroup } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import Papa from 'papaparse';
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { LANGUAGE } from "../../constants/localStorage";
import { getData } from "../../services/storage";
import { getLocaleString } from "../../utils/locales";

// Fallback data
const FALLBACK_DATA = {
  yearlyData: {
    "2025": [142741.72, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "2024": [112402.4, 110653.4, 114034.8, 119596.62, 119503.34, 118314.02, 120039.7,
      127898.54, 138660.72, 143476.3, 143476.3, 140537.98],
    "2023": [93428.64, 93428.64, 97627.68, 99144.00, 98410.40, 98410.40, 100742.40,
      101208.80, 101208.80, 102841.20, 109867.20, 111003.20]
  },
  sixYearData: [
    { year: "2019", price: 59186.16 },
    { year: "2020", price: 76341.00 },
    { year: "2021", price: 74300.00 },
    { year: "2022", price: 91912.00 },
    { year: "2023", price: 111003.20 },
    { year: "2024", price: 143476.30 },
    { year: "2025", price: 142741.72 }
  ],
  tenYearData: [
    { year: "2014", price: 48400.66 },
    { year: "2015", price: 44521.00 },
    { year: "2016", price: 47118.06 },
    { year: "2017", price: 50155.00 },
    { year: "2018", price: 52248.46 },
    { year: "2019", price: 59186.16 },
    { year: "2020", price: 76341.00 },
    { year: "2021", price: 74300.00 },
    { year: "2022", price: 91912.00 },
    { year: "2023", price: 111003.20 },
    { year: "2024", price: 143476.30 },
    { year: "2025", price: 142741.72 },
  ]
};

const CACHE_KEY = 'goldPriceData';
const CACHE_DURATION = 2 * 60 * 60 * 1000; // 12 hours in milliseconds

interface GoldPriceData {
  yearlyData: { [key: string]: number[] };
  sixYearData: { year: string; price: number }[];
  tenYearData: { year: string; price: number }[];
  timestamp?: number;
}

const SPREADSHEET_ID = '17l7gUNr1QBWjNxufOTS5A9zDOCw1qQb_s6BWiaieV5E';
const YearlySheetGID = '134202101';
const SixYearSheetGID = '1063350062';
const TenYearSheetGID = '653645493';

function GoldChart() {
  const [duration, setDuration] = useState("1");
  const [selectedYear, setSelectedYear] = useState("2023");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartData, setChartData] = useState<GoldPriceData>(FALLBACK_DATA);
  const [, setIsUsingCache] = useState(false);
  
  const locale = getData(LANGUAGE);


  const checkCache = (): GoldPriceData | null => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const parsedCache = JSON.parse(cached);
    const now = Date.now();
    
    if (now - parsedCache.timestamp < CACHE_DURATION) {
      return parsedCache;
    }
    
    localStorage.removeItem(CACHE_KEY);
    return null;
  };

  const parseYearlyData = (yearlyResult: any) => {
    const yearlyData: { [key: string]: number[] } = {};
    let currentYear = '';
    
    yearlyResult.data.forEach((row: string[]) => {
      if (row[0].match(/^20\d{2}$/)) {
        currentYear = row[0];
        if (!yearlyData[currentYear]) {
          yearlyData[currentYear] = Array(12).fill(0);
        }
      }
      else if (row[0].match(/^(January|February|March|April|May|June|July|August|September|October|November|December)$/)) {
        const monthIndex = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ].indexOf(row[0]);
        
        if (monthIndex !== -1 && currentYear && row[1]) {
          const price = parseFloat(row[1].replace(/[\s,]/g, ''));
          yearlyData[currentYear][monthIndex] = price;
        }
      }
    });
    return yearlyData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Check cache first
        const cachedData = checkCache();
        if (cachedData) {
          setChartData(cachedData);
          setIsUsingCache(true);
          setLoading(false);
          return;
        }

        const yearlyDataUrl = `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/gviz/tq?tqx=out:csv&gid=${YearlySheetGID}#gid=${YearlySheetGID}`;
        const sixYearDataUrl = `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/gviz/tq?tqx=out:csv&gid=${SixYearSheetGID}#gid=${SixYearSheetGID}`;
        const tenYearDataUrl = `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/gviz/tq?tqx=out:csv&gid=${TenYearSheetGID}#gid=${TenYearSheetGID}`;

        const [yearlyResponse, sixYearResponse, tenYearResponse] = await Promise.all([
          fetch(yearlyDataUrl),
          fetch(sixYearDataUrl),
          fetch(tenYearDataUrl)
        ]);

        if (!yearlyResponse.ok || !sixYearResponse.ok || !tenYearResponse.ok) {
          throw new Error('Failed to fetch data from spreadsheet');
        }

        const [yearlyCSV, sixYearCSV, tenYearCSV] = await Promise.all([
          yearlyResponse.text(),
          sixYearResponse.text(),
          tenYearResponse.text()
        ]);


        const newData: GoldPriceData = {
          yearlyData: {},
          sixYearData: [],
          tenYearData: [],
          timestamp: Date.now()
        };

        // Parse yearly data
        Papa.parse(yearlyCSV, {
          header: false,
          complete: (yearlyResult) => {
            newData.yearlyData = parseYearlyData(yearlyResult);

            // Parse six-year data
            Papa.parse(sixYearCSV, {
              header: false,
              complete: (sixYearResult) => {
                const sixYearData = sixYearResult.data
                  .filter((value: unknown) => (value as string[])[0]?.match(/^20\d{2}$/))
                  .map((value: unknown) => {
                    const row = value as string[];
                    return {
                      year: row[0],
                      price: parseFloat(row[1]?.replace(/[\s,]/g, '') || '0')
                    };
                  });
                newData.sixYearData = sixYearData;

                // Parse ten-year data
                Papa.parse(tenYearCSV, {
                  header: false,
                  complete: (tenYearResult) => {
                    const tenYearData = tenYearResult.data
                      .filter((value: unknown) => (value as string[])[0]?.match(/^20\d{2}$/))
                      .map((value: unknown) => {
                        const row = value as string[];
                        return {
                          year: row[0],
                          price: parseFloat(row[1]?.replace(/[\s,]/g, '') || '0')
                        };
                      });
                    newData.tenYearData = tenYearData;

                    // Save to cache and update state
                    localStorage.setItem(CACHE_KEY, JSON.stringify(newData));
                    setChartData(newData);
                    setIsUsingCache(false);
                    setLoading(false);
                  }
                });
              }
            });
          }
        });

      } catch (e) {
        console.error('Error fetching data:', e);
        setError('Failed to fetch latest data. Using fallback data.');
        console.log(error);
        setChartData(FALLBACK_DATA);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getSeries = () => {
    if (loading) return [];

    if (duration === "1") {
      return [{
        name: selectedYear,
        data: chartData.yearlyData[selectedYear] || Array(12).fill(0)
      }];
    } else if (duration === "2") {
      return [{
        name: "Gold Price",
        data: chartData.sixYearData.map(item => item.price)
      }];
    } else {
      return [{
        name: "Gold Price",
        data: chartData.tenYearData.map(item => item.price)
      }];
    }
  };

  const getCategories = () => {
    if (duration === "1") {
      return [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
    } else if (duration === "2") {
      return chartData.sixYearData.map(item => item.year);
    } else {
      return chartData.tenYearData.map(item => item.year);
    }
  };

  const options: ApexOptions = {
    plotOptions: {
      bar: {
        dataLabels: {
          orientation: 'vertical',
          position: 'center'
        }
      }
    },
    chart: {
      width: "100%",
      height: 350,
      type: "bar",
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val === 0 ? "" : `৳ ${val.toLocaleString()}`,
      style: {
        colors: ["#333"],
        fontSize: "10px",
        fontWeight: "normal",
      },
      offsetY: -10,
    },
    colors: ["#e9be5a"],
    xaxis: {
      categories: getCategories()
    },
    yaxis: {
      labels: {
        formatter: (val) => `৳ ${val.toLocaleString()}`,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => `৳ ${val.toLocaleString()}`,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  return (
    <div className="lg:w-1/2 space-y-5">
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold">
          {getLocaleString("chartHeader", locale)}
        </p>
        {/* {isUsingCache && (
          <span className="text-sm text-gray-500">
            Using cached data (Last updated: {new Date(chartData.timestamp || 0).toLocaleString()})
          </span>
        )} */}
      </div>
      
      {/* {error && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <p className="text-yellow-700">{error}</p>
        </div>
      )} */}

      {loading ? (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="flex justify-center">
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                style={{
                  backgroundColor: duration === "1" ? "#e9be5a" : "#ffffffff",
                }}
                onClick={() => setDuration("1")}
              >
                {getLocaleString("yearly", locale)}
              </Button>
              <Button
                style={{
                  backgroundColor: duration === "2" ? "#e9be5a" : "#ffffffff",
                }}
                onClick={() => setDuration("2")}
              >
                {getLocaleString("Sixyear", locale)}
              </Button>
              <Button
                style={{
                  backgroundColor: duration === "3" ? "#e9be5a" : "#ffffffff",
                }}
                onClick={() => setDuration("3")}
              >
                {getLocaleString("Tenyear", locale)}
              </Button>
            </ButtonGroup>
          </div>

          {duration === "1" && (
            <div className="flex justify-center space-x-4 mb-4">
              {Object.keys(chartData.yearlyData).map(year => (
                <Button
                  key={year}
                  variant={selectedYear === year ? "contained" : "outlined"}
                  onClick={() => setSelectedYear(year)}
                >
                  {year}
                </Button>
              ))}
            </div>
          )}

          <ReactApexChart 
            options={options} 
            series={getSeries()} 
            type="bar" 
            height="350" 
          />
        </>
      )}
    </div>
  );
}

export default GoldChart;